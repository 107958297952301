import { defineStore } from 'pinia';
import axios from 'axios';
import { DOCUMENT_TYPES } from "@/lib/constants";

export type TaskState = {
  code: string,
  name: string,
};

export type AgreementTask = { // Согласование
  id: string,
  document_type: DocumentType,
  task_type: TaskTypeObj,
  state: TaskState | null,
  reg_num: string,
  title: string,
  author: string,
  entity: string | null,
  is_assignment: boolean,
  is_new?: boolean,
  created_at: string,
};

export type AssignmentTask = { // Поручение
  id: string,
  author: string,
  document_type: DocumentType,
  current_performers: string | null,
  task_type: TaskTypeObj,
  state: TaskState | null,
  reg_num: string,
  title: string,
  implementer: string,
  entity: string | null,
  is_assignment: boolean,
  expired_at: string,
  is_expired: boolean,
  created_at: string,
  is_new?: boolean,
};

export type PercentageTask = { // Допуск к процентованию
  id: string,
  document_type: DocumentType,
  task_type: TaskTypeObj,
  state: TaskState | null,
  reg_num: string,
  title: string,
  author: string,
  entity: string | null,
  object: string,
  is_assignment: boolean,
  is_new?: boolean,
  created_at: string,
};

export type VacancyTask = { // Подбор персонала
  id: number,
  document_type: DocumentType,
  task_type: TaskTypeObj,
  title: string,
  author: string,
  is_assignment: boolean,
  created_at: string,
  is_new?: boolean;
};

export type TenderTask = { // ТЗ на тендер
  id: string,
  document_type: DocumentType,
  task_type: TaskTypeObj,
  state: TaskState | null,
  tender_date: string,
  reg_num: string,
  title: string,
  author: string,
  is_assignment: boolean,
  is_new?: boolean,
};

export type Task = AgreementTask | AssignmentTask | TenderTask | VacancyTask | PercentageTask;

export enum TaskType {
  agreement = 'agreement',
  assignment = 'assignment',
  signing = 'signing',
}

type TaskTypeObj = {
  code: string,
  name: string,
};

type DocumentType = {
  code: string,
  name: string,
};

type Filters = {
  taskType: TaskType,
  documentTypes: string[],
  searchQuery: string
};

const enum StorageKeys {
  shown_tasks = 'tasks:shown',
  tasks_filters = 'tasks:filters',
}

interface TasksState {
  tasks: Task[],
  filters: Filters,
  shownTasks: string[],
}

export const useTasksStore = defineStore('tasks', {
  state: (): TasksState => ({
    tasks: [],
    filters: {
      taskType: TaskType.agreement,
      documentTypes: [],
      searchQuery: '',
    },
    shownTasks: [],
  }),
  getters: {
    filteredTasks(state: TasksState): Task[] {
      const taskType: TaskType = state.filters.taskType;
      let tasks: Task[] = this.filtrateTasksByTaskType(this.tasks, taskType);
      tasks = this.filtrateTasksByDocType(tasks);
      return this.filtrateTasksBySearchQuery(tasks);
    },
    filteredAgreementsTasksCount(): number {
      let tasks: Task[] = this.filtrateTasksByTaskType(this.tasks, TaskType.agreement);
      tasks = this.filtrateTasksByDocType(tasks);
      return this.filtrateTasksBySearchQuery(tasks).length;
    },
    filteredAssignmentTasksCount(): number {
      let tasks: Task[] = this.filtrateTasksByTaskType(this.tasks, TaskType.assignment);
      tasks = this.filtrateTasksByDocType(tasks);
      return this.filtrateTasksBySearchQuery(tasks).length;
    },
    filteredSigningTasksCount(): number {
      let tasks: Task[] = this.filtrateTasksByTaskType(this.tasks, TaskType.signing);
      tasks = this.filtrateTasksByDocType(tasks);
      return this.filtrateTasksBySearchQuery(tasks).length;
    },
    filtrateTasksBySearchQuery: (state: TasksState) => {
      const searchQuery: string = state.filters.searchQuery.toLowerCase().replaceAll(' ', '');

      return (tasks: Task[]): Task[] => {
        if (searchQuery.length <= 2) {
          return tasks;
        }

        const searchFields: string[] = [
          'title',
          'entity',
          'reg_num',
          'author',
          'implementer',
          'created_at',
        ];

        return tasks.filter((task: Task): boolean => {
          let searchString: string = '';

          searchFields.forEach((field: string): void => {
            let fieldKey = field as keyof Task;
            if (task[fieldKey]) {
              searchString += task[fieldKey]?.toString().toLowerCase().replaceAll(' ', '');
            }
          });

          return searchString.indexOf(searchQuery) !== -1;
        });
      };
    },
    filtrateTasksByDocType: (state: TasksState) => {
      const docTypes: string[] = state.filters.documentTypes;

      return (tasks: Task[]): Task[] => {
        if (docTypes.length <= 0) {
          return tasks;
        }

        return tasks.filter((task: Task): boolean => {
          const isDocumentHasRequiredType: boolean = docTypes.includes(task.document_type.code);

          if (isDocumentHasRequiredType) {
            return true;
          }

          const hasFilterByOtherType: boolean = docTypes.includes('other');

          if (hasFilterByOtherType) {
            return !DOCUMENT_TYPES.includes(task.document_type.code);
          }

          return false;
        });
      };
    },
    filtrateTasksByTaskType: () => {
      return (tasks: Task[], taskType: TaskType): Task[] => {
        return tasks.filter((task: Task) => task.task_type.code === taskType);
      };
    },
  },
  actions: {
    reset() {
      this.$reset();
    },
    fetchTasks(useCache: boolean = true): Promise<Task[]> {
      return new Promise((resolve, reject) => {
        let url: string = '/api/tasks';

        if (!useCache) {
          url += '?without_cache'
        }

        axios.get<{ data: Task[] }>(url).then(
          response => {
            this.tasks = response.data.data.map((task: Task): Task => {
              return {
                ...task,
                is_new: !this.shownTasks.includes(String(task.id)),
              };
            });

            resolve(this.tasks);
          },
          error => {
            reject(error)
          }
        );
      });
    },
    fetchTasksFilters(): void {
      const defaultFilters: Filters = {
        taskType: TaskType.agreement,
        documentTypes: ['memo'],
        searchQuery: '',
      };

      const rawStorageFilters: string = window.localStorage.getItem(
        StorageKeys.tasks_filters
      ) || '{}';

      const storageFilters = JSON.parse(rawStorageFilters);

      let filterKey: keyof Filters;

      for (filterKey in defaultFilters) {
        if (storageFilters.hasOwnProperty(filterKey)) {
          this.filters[filterKey] = storageFilters[filterKey];
        }
      }
    },
    fetchShownTasks(): void {
      const shownTasks: string = window.localStorage.getItem(StorageKeys.shown_tasks) || '[]';
      this.shownTasks = JSON.parse(shownTasks) as string[];
    },
    setShownTask(id: string): void {
      if (!this.shownTasks.includes(id)) {
        const shownTasks = [ ...this.shownTasks, id ];

        const currLen = shownTasks.length;
        const maxLen = 50;

        this.shownTasks = currLen > maxLen
          ? shownTasks.slice(currLen - maxLen)
          : shownTasks;

        window.localStorage.setItem(
          StorageKeys.shown_tasks,
          JSON.stringify(this.shownTasks)
        );
      }
    },
    setFiltersTaskType(taskType: TaskType): void {
      this.filters.taskType = taskType;
      this.storeFiltersInLocalStorage();
    },
    setFiltersDocTypes(documentTypes: string[]): void {
      this.filters.documentTypes = documentTypes;
      this.storeFiltersInLocalStorage();
    },
    setFiltersSearchQuery(query: string): void {
      this.filters.searchQuery = query.trim();
      this.storeFiltersInLocalStorage();
    },
    storeFiltersInLocalStorage(): void {
      window.localStorage.setItem(
        StorageKeys.tasks_filters,
        JSON.stringify(this.filters)
      );
    },
    removeFiltersInLocalStorage(): void {
      window.localStorage.removeItem(StorageKeys.tasks_filters)
    }
  },
});
