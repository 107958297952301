import { AxiosError, AxiosResponse } from 'axios';

type ApiError = {
  response: AxiosResponse
}

export const useHttpErrors = {
  isAuthorizationRequiredError(error: AxiosError | ApiError): boolean {
    return error.response?.status === 401 && error.response?.data?.error === 'AuthorizationRequired'
  },
  isTokenExpiredError(error: AxiosError | ApiError): boolean {
    return error.response?.status === 401 && error.response?.data?.error === 'TokenExpired'
  },
  isDelegationNotPossibleError(error: AxiosError | ApiError): boolean {
    return error.response?.status === 409 && error.response?.data?.error === 'DelegationNotPossible'
  },
  isTaskProcessedError(error: AxiosError | ApiError): boolean {
    return error.response?.status === 409 && error.response?.data?.error === 'TaskProcessed'
  },
  isTaskNotFoundError(error: AxiosError | ApiError): boolean {
    return error.response?.status === 404 && error.response?.data?.error === 'TaskNotFound'
  },
  isValidationErrorsError(error: AxiosError | ApiError): boolean {
    return error.response?.status === 422 && error.response?.data?.error === 'ValidationErrors'
  },
}


