import { createRouter, createWebHistory, Router } from 'vue-router';

const TasksPage = () => import('@/pages/TasksPage.vue');
const VacancyPage = () => import('@/pages/VacancyPage.vue');
const DocumentPage = () => import('@/pages/DocumentPage.vue');
const OrderPage = () => import('@/pages/OrderPage.vue');
const DelegatePage = () => import('@/pages/DelegatePage.vue');
const LoginPage = () => import('@/pages/LoginPage.vue');
const NotFoundPage = () => import('@/pages/NotFoundPage.vue');

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'tasks',
      component: TasksPage,
    },
    {
      path: '/documents/:id',
      name: 'document',
      component: DocumentPage,
    },
    {
      path: '/vacancies/:id',
      name: 'vacancy',
      component: VacancyPage,
    },
    {
      path: '/order/:id',
      name: 'order',
      component: OrderPage,
    },
    {
      path: '/delegate/:id',
      name: 'delegate',
      component: DelegatePage,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundPage
    },
  ],
});

export default router;
