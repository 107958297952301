import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios, { AxiosError } from 'axios'
import VueAxios from 'vue-axios'
import { Router } from 'vue-router';
import router from '@/router';
import dateFilter from '@/filters/date.filter';
import price from '@/filters/price.filter';
import { useAuthStore } from '@/stores/auth.store';
import { useAppStore } from '@/stores/app.store';
import { useErrorStore } from '@/stores/error.store';
import { useHttpErrors } from '@/composables/useHttpErrors';
import '@/assets/scss/app.scss';

import App from '@/App.vue';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(VueAxios, axios);
app.config.globalProperties.$filters = {
  date: dateFilter,
  price,
}

const httpError = useHttpErrors;

const setupAxiosInterceptors = (router: Router) => {
  axios.interceptors.response.use(
    response => response,
    error => {
      const errorState = useErrorStore();

      if (!errorState.isGlobalErrorHandlerActive) {
        return Promise.reject(error);
      }

      if (httpError.isValidationErrorsError(error)) {
        return Promise.reject(error);
      }

      errorState.fillErrorState(error);

      const authStore = useAuthStore();
      const appStore = useAppStore();
      const route = router.currentRoute.value;

      appStore.hidePreloader();

      const shouldRedirectToLoginPage = error instanceof AxiosError
        && (httpError.isAuthorizationRequiredError(error) || httpError.isTokenExpiredError(error));

      if (shouldRedirectToLoginPage) {
        authStore.removeToken();
        appStore.setRememberedUrl(route.path);
        router.push('/login');
        return;
      }

      return Promise.reject(error);
    }
  );
}

setupAxiosInterceptors(router);

router.beforeEach((to, from, next) => {
  const errorState = useErrorStore();
  const authStore = useAuthStore();
  const isAuthenticated = !!authStore.getToken;

  errorState.resetErrorState();

  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
})

app.mount('#app');
