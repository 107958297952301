import { defineStore } from 'pinia';
import axios from 'axios';

type AuthState = {
  token: string | null;
};

const AUTH_TOKEN_STORAGE_KEY: string = 'auth:token';

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: null,
  }),
  getters: {
    getToken(state: AuthState): string | null {
      if (!state.token) {
        state.token = window.localStorage.getItem(AUTH_TOKEN_STORAGE_KEY) || null;
      }

      return state.token;
    },
  },
  actions: {
    setToken(token: string): void {
      window.localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
      this.token = token;
    },
    removeToken(): void {
      delete axios.defaults.headers.common?.Authorization;
      window.localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
      this.token = null;
    },
  },
});
