import { defineStore } from 'pinia';
import { AxiosError } from 'axios';
import { useHttpErrors } from '@/composables/useHttpErrors';

export type ErrorState = {
  showError: boolean,
  errorTitle: null | string | undefined,
  errorMessage: null | string | undefined,
  isGlobalErrorHandlerActive: boolean,
}

const httpError = useHttpErrors;

export const useErrorStore = defineStore('error', {
  state: (): ErrorState => ({
    showError: false,
    errorTitle: null,
    errorMessage: null,
    isGlobalErrorHandlerActive: true,
  }),
  actions: {
    enableGlobalErrorHandler(): void {
      this.isGlobalErrorHandlerActive = true;
    },
    disableGlobalErrorHandler(): void {
      this.isGlobalErrorHandlerActive = false;
    },
    fillErrorState(error: AxiosError | any): void {
      this.showError = true;
      this.errorTitle = 'Произошла ошибка';
      this.errorMessage = 'При отправке данных на сервер возникла непредвиденная ошибка. ' +
        'Пожалуйста, обновите страницу и попробуйте снова.';

      if (error instanceof AxiosError) {
        if (httpError.isDelegationNotPossibleError(error)) {
          this.errorTitle = 'Запрос не выполнен'
          this.errorMessage = 'К сожалению, данное задание невозможно делегировать.';
        }

        if (httpError.isTaskProcessedError(error)) {
          this.errorTitle = 'Задание уже обработано'
          this.errorMessage = 'Задание, которое вы попытались открыть, было завершено вами в ' +
            'Docsvision или другим сотрудником.';
        }

        if (httpError.isTaskNotFoundError(error)) {
          this.errorTitle = 'Задание не найдено'
          this.errorMessage = 'Задание, которое вы попытались открыть, в системе не зарегистрировано.\n' +
            'Если вы считаете, что это ошибка, напишите запрос на email\n' +
            '<a style="color: #5d69f8;" href="mailto:dv_support@spbrealty.ru" target="_blank">\n' +
            'dv_support@spbrealty.ru\n</a> с указанием ссылки на данную страницу.';
        }
      }
    },
    resetErrorState(): void {
      this.showError = false;
      this.errorTitle = '';
      this.errorMessage = '';
    }
  },
});
